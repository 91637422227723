@charset "utf-8";
@import "base";
/*====================================================================

index.css

=====================================================================*/

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_sp_hight: floor-decimal(720 / 480 * 100) * 1vw; //スマホの高さ
$top_tb_hight: 100vw;
$top_pc_hight: 900px;
.main-view {
	position: relative;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: $top_pc_hight;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
	& .l-cont {
		display: flex;
		align-items: center;
		height: 100%;
		z-index: z-index(module, part02);
		@include media-breakpoint-down(xs) {
			justify-content: center;
		}
	}
}

.main-view__bg {
	position: relative;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: $top_pc_hight;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
	&__img {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(md) {
			height: $top_tb_hight;
		}
		@include media-breakpoint-up(lg) {
			height:  $top_pc_hight;
		}
		@include media-breakpoint-down(sm) {
			height: $top_sp_hight;
		}
	}
}

.main-view__inner {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: z-index(module, part04);
}

.main-view__txt {
	z-index: z-index(module, part01);
	width: 100%;
}
.catch-01,.catch-02,.catch-03 {
	@include f-family(font03,serif);
	@include fs-xxs(20, 68);
	color: $white;
	@include l-sp(0.05em);
	text-shadow: 0px 0px 13px rgba($black, 0.7);
	@include f-w(500);
	@include media-breakpoint-down(xs) {
		text-align: center;
	}
}

/* scroll */
.scroll-line {
	position: absolute;
	left: unquote("min(2%,4rem)");
	bottom: -2.5rem;
	writing-mode: vertical-rl;
	z-index: z-index(module, part01);
	a {
		@include dec-none;
		@include f-family(font02);
		@include f-w(400);
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $black;
		@include transition;
		&:hover {
			color: $main_c;
		}
	}
	&__txt {
		@include l-sp(0);
	}
	span {
		content: "";
		inline-size: 5rem;
		block-size: 1px;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: $black;
			@include transition;
			block-size: 1px;
			inline-size: 100%;
			animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}
}

@keyframes scroll-line {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	51% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}
	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}

/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/

//about-cont
.about-cont {
	position: relative;
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	overflow-x: hidden;
	@include media-breakpoint-up(lg) {
		padding-bottom: clamp(10rem, 12vw, 14rem);
	}
	&__inner {
		position: relative;
		display: flex;
		@include media-breakpoint-down(md) {
			@include m-a;
			flex-direction: column-reverse;
			padding-left: map-get($contmargin, tb);
			padding-right: map-get($contmargin, tb);
		}
		@include media-breakpoint-down(sm) {
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 50%;
			@include flex-end;
			align-items: center;
		}
		&__inner {
			@include media-breakpoint-up(lg) {
				padding-right: 8vw;
				padding-left: 6vw;
				width: unquote("min(50vw,740px)");
			}
			@include media-breakpoint-up(xl) {
				padding-left: map-get($contmargin, pc);
			}
		}
		.txt {
			p {
				line-height: 2;
			}
		}
	}
}

.about-cont__img {
	@include media-breakpoint-up(lg) {
		width: 50%;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
		figure {
			position: relative;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include aspect-img(5, 4);
				overflow: visible;
				width: unquote("min(45vw,864px)");
			}
			@include media-breakpoint-down(md) {
				@include aspect-img(3, 2);
				margin-bottom: calc(2rem + 6vw);
			}
			&::after {
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 100%;
				background: $other_c1;
				z-index: -1;
				@include media-breakpoint-up(lg) {
					left: 12%;
					top: 6vw;
				}
				@include media-breakpoint-up(xxl) {
					top: 6rem;
				}
				@include media-breakpoint-down(md) {
					top: 6vw;
					left: 6vw;
				}
			}
		}
	}
	img {
		width: 100%;
		display: block;
	}
}

.freecont01-cont {
	overflow: hidden;
	position: relative;
	background: $sub_c;
	@include cont-padding;
	&__tit {
		@include cont-tit;
		margin-bottom: calc(2rem + 2em);
		text-align: center;
	}
	&__txt {
		margin-bottom: clamp(3rem, 4vw, 4rem);
		@include m-a;
		max-width: map-get($container-max-widths, lg);
	}
}

/* freecontent02 */
.freecont02-cont {
	overflow: hidden;
	position: relative;
	@include cont-padding;
	&__tit {
		@include cont-tit;
		margin-bottom: calc(2rem + 2em);
		text-align: center;
	}
	&__txt {
		margin-bottom: clamp(3rem, 4vw, 4rem);
		@include m-a;
		max-width: map-get($container-max-widths, lg);
	}
}

/* works-cont */
.works-cont {
	@include cont-padding;
	.l-cont {
		position: relative;
	}
	&__txt {
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
		}
		margin-bottom: calc(2rem + 0.75em);
		.cont-tit {
			@include media-breakpoint-up(xl) {
				padding-right: 3em;
			}
			@include media-breakpoint-down(lg) {
				margin-bottom: calc(1rem + 0.3em);
			}
		}
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		max-width: 360px;
		& button {
			@include f-em(18);
		}
	}
}

/*---------------------------------------------------------
 staff
---------------------------------------------------------*/
.staff-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	position: relative;
	@include bg_dot($m-gray, $o-white);
	z-index: z-index(module, part04);
	@include media-breakpoint-down(xs) {
		padding-top: clamp(10rem, 40vw, 20rem);
	}
	&__llst {
		& > * {
			&:not(:last-of-type) {
				padding-bottom: clamp(2rem, 3vw, 3rem);
				@include media-breakpoint-up(md) {
					padding-bottom: clamp(2rem, 6vw, 6rem);
				}
			}
		}
	}
	.none-txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}
}

/* staff-box */
$staff_img_width: 28.33%; //画像の幅
$staff_txt_width: 60%; //テキストの幅

.staff-box {
	padding-top: clamp(2rem, 3vw, 3rem);
	&:not(:last-of-type) {
		padding-bottom: clamp(2rem, 3vw, 3rem);
	}

	@include media-breakpoint-up(md) {
	}
	&__inner {
		&.is-left {
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: $staff_img_width $staff_txt_width;
				grid-template-rows: auto 1fr;
				column-gap: 11.66%;
				.staff-box__img {
					grid-column: 1 / 2;
				}
				.staff-box__tit {
					grid-column: 2 / 3;
				}
				.staff-box__txt {
					grid-column: 2 / 3;
				}
			}
		}
		&.is-right {
			@include media-breakpoint-up(md) {
				display: grid;
				grid-template-columns: $staff_txt_width $staff_img_width;
				grid-template-rows: auto 1fr;
				column-gap: 11.66%;
				.staff-box__img {
					grid-column: 2 / 3;
				}
				.staff-box__tit {
					grid-column: 1 / 2;
				}
				.staff-box__txt {
					grid-column: 1 / 2;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			max-width: 480px;
			@include m-a;
		}
	}
	&__tit {
		color: $black;
		@include f-w(500);
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: clamp(1rem, 2vw, 1.5rem);
		border-bottom: 1px solid $m-gray;
		@include f-s_xs(1.07, 6);
		@include media-breakpoint-up(md) {
			grid-row: 1 / 2;
			@include f-s_lg(1.25, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			width: calc(100% - 1rem);
			margin-bottom: 2rem;
		}
		&__inner {
			display: block;
			position: relative;
			&.light-box {
				overflow: visible !important;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
				height: 66.66%;
			}
			&::before {
				position: absolute;
				top: 1rem;
				left: 1rem;
				content: "";
				background: $main_c;
				width: 100%;
				height: 100%;
				@include media-breakpoint-up(md) {
					top: 2rem;
					left: 2rem;
				}
			}
			figure {
				@include media-breakpoint-up(md) {
					@include aspect-img(1, 1);
				}
				@include media-breakpoint-down(sm) {
					@include aspect-img();
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
	}
}

/*---------------------------------------------------------
 qa
---------------------------------------------------------*/
.qa-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	background: rgba($white, 0.6);
	&__llst {
		position: relative;
		counter-reset: qa-num;
		& > * {
			&:not(:last-of-type) {
				padding-bottom: clamp(2rem, 3vw, 3rem);
				@include media-breakpoint-up(md) {
					padding-bottom: clamp(2rem, 6vw, 6rem);
				}
				&::after {
					position: absolute;
					top: 0;
					left: calc(50% - 1px);
					display: block; 
					width: 2px;
					height: 100%;
					content: '';
					background: #0c0601;
					@include media-breakpoint-up(md) {
						left: 50px;
					}
					@include media-breakpoint-up(lg) {
						left: 5%;
					}
				}
		
			}
		}
	}
	.none-txt {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}
}
.qa-box {
	position: relative;
	z-index: 1;
	padding: 2rem;
	background-color: $sub_c;
	@include media-breakpoint-up(lg) {
		padding: 4%;
	}
	&__tit {
		position: relative;
		padding-left: 50px;
		color: $black;
		margin-bottom: clamp(1rem, 2vw, 1.5rem);
		@include media-breakpoint-up(xl) {
			padding-left: 60px;
		}
		&::before {
			counter-increment: qa-num;
			content: "" counter(qa-num, decimal-leading-zero);
			position: absolute;
			@include f-family(font03,serif);
			top: 0px;
			left: 0;
			@include line-h(1);
			color: $main_c;
			@include f-s_xs(2, 8);
			@include l-sp(0);
			@include media-breakpoint-up(xl) {
				@include f-size(40);
			}
		}
		span {
			@include f-w(500);
			@include f-s_xs(1.14, 4);
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
		}
	}
}

/* message */
.message-cont {
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(8rem, 20vw, 24rem);
	position: relative;
	background: $other_c2;
	z-index: z-index(module, part04);
	overflow: hidden;
	@include media-breakpoint-down(md) {
		background: $other_c1;
	}
	@include media-breakpoint-down(xs) {
		padding-top: clamp(10rem, 40vw, 20rem);
	}
	& .l-cont {
		position: relative;
		z-index: z-index(module, part02);
	}
	&__inner {
		position: relative;
		@include media-breakpoint-down(md) {
			background: $white;
			padding-top: clamp(3rem, 8vw, 10rem);
			padding-bottom: clamp(3rem, 8vw, 10rem);
			padding-right: unquote("min(10%, 4rem)");
			padding-left: unquote("min(10%, 4rem)");
		}
		@include media-breakpoint-down(xs) {
			padding-top: clamp(11rem, 48vw, 16rem);
		}
		&::after {
			@include media-breakpoint-up(lg) {
				content: "";
				background: $other_c1;
				width: unquote("min(65vw,1060px)");
				height: unquote("min(65vw,1060px)");
				position: absolute;
				bottom: unquote("min(-7.5vw,-144px)");
				left: unquote("min(-7.5vw,-144px)");
				z-index: -1;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			background: $white;
			max-width: 1120px;
			width: 77.77%;
			padding-top: clamp(6rem, 8vw, 9rem);
			padding-right: clamp(5rem, 12vw, 14rem);
			padding-left: clamp(5rem, 9vw, 10rem);
			padding-bottom: clamp(6rem, 8vw, 8rem);
			z-index: z-index(module, part03);
			position: relative;
		}
	}
}
.message-name {
	margin-top: 2rem;
	@include f-w(600);
	@include f-em(20);
	@include line-h(1.4);
	@include l-sp(0.2em);
}

.message-cont__img {
	z-index: z-index(module, part01);
	@include media-breakpoint-up(lg) {
		position: absolute;
		max-width: 440px;
		width: 30.55%;
		top: unquote("min(15vw,15rem)");
		right: 0;
	}
	@include media-breakpoint-between(sm, md) {
		float: right;
		margin-top: 2rem;
		margin-left: 5%;
		margin-bottom: 1.5rem;
		max-width: 280px;
		width: 40%;
	}
	@include media-breakpoint-down(xs) {
		position: absolute;
		width: clamp(160px, 60%, 200px);
		top: clamp(-80px, -35vw, -120px);
		left: 50%;
		transform: translateX(-50%);
	}
	&__inner {
		@include m-a;
	}
	figcaption {
		margin-top: 0.7rem;
	}
	&__caption {
		width: 75%;
		@include m-a;
	}
}

.message-cont__bg {
	position: absolute;
	top: 0;
	content: "";
	display: block;
	z-index: z-index(module, part03);
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		right: 0;
		width: 70%;
		height: 100%;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		height: 40%;
		left: 0;
	}
	&__inner {
		position: relative;
		height: 100%;
		&::after {
			content: "";
			background: rgba($white, 0.2);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		& > * {
			height: 100%;
		}
	}
}

/* philosophy */
$height_bg_sp: 70%; //sp表示の際の背景の高さ
$height_bg_pc: 50%; //PC表示の際の背景の高さ
$height_bg_white_sp: 100% - $height_bg_sp; //sp表示の際の背景の高さ
$height_bg_white_pc: 100% - $height_bg_pc; //PC表示の際の背景の高さ

.philosophy-cont {
	position: relative;
	padding-top: clamp(6rem, 9vw, 12rem);
	padding-bottom: clamp(8rem, 18vw, 20rem);
	overflow: hidden;
	&::before {
		position: absolute;
		top: 0;
		content: "";
		background: $other_c2;
		width: 100%;
		height: $height_bg_white_sp;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			height: $height_bg_white_pc;
		}
	}
}

.philosophy-cont__inner {
	position: relative;
	padding: clamp(2rem, 8vw, 6rem) clamp(4%, 8vw, 12rem);
	background: $white;
	z-index: z-index(module, part01);
	border: 1px solid $m-gray;
	@include media-breakpoint-up(md) {
		padding: clamp(2rem, 8vw, 6rem) 0;
	}
	.txt {
		@include line_h(2);
		.catch {
			@include f-family(font03,serif);
			@include line_h(1.5);
			@include fs_xs(17, 30);
		}
	}
}

.philosophy-cont__img {
	position: absolute;
	width: 100%;
	height: $height_bg_sp;
	bottom: 0;
	background: $sub_c;
	@include media-breakpoint-up(md) {
		height: $height_bg_pc;
	}
	&__inner {
		position: relative;
		height: 100%;
		[class*='meat-'] {
			z-index: 10;
		}
		.meat-01{
			position: absolute;
			top: -190px;
			left: 0;
			width: 30%;
			max-width: 232px;
			@include media-breakpoint-up(md) {
				width: 26%;
				top: -60%;
			}
			@include media-breakpoint-up(xl) {
				max-width: 300px;
				left: calc(50% - 900px);
			}
		}
		.meat-02{
			position: absolute;
			top: -80%;
			right: 20px;
			width: 30%;
			max-width: 208px;
			@include media-breakpoint-up(xl) {
				max-width: 300px;
				right: calc(50% - 800px);
			}
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
		.meat-03{
			position: absolute;
			top: 180px;
			left: 10%;
			width: 30%;
			max-width: 213px;
			@include media-breakpoint-up(xl) {
				max-width: 300px;
				left: calc(50% - 800px);
			}

			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
		.meat-04{
			position: absolute;
			bottom: 30px;
			right: 15px;
			width: 40%;
			max-width: 243px;
			@include media-breakpoint-up(md) {
				top: 220px;
			}
			@include media-breakpoint-up(xl) {
				top: 250px;
				max-width: 350px;
				right: calc(50% - 700px);
			}

		}
		picture {
			height: 100%;
		}
	}
}

/* company */
.company-cont {
	z-index: z-index(module, part03);
	position: relative;
	@include cont-padding;
	&__bg {
		//企業理念を使用しない場合
		display: block;
		width: 100%;
		height: clamp(200px, 40vw, 600px);
		position: relative;
		&__img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			overflow: hidden;
			&__inner {
				position: relative;
				height: 100vw;
			}
		}
	}
}

/* accessmap */
.accessmap {
	&-box {
		width: 100%;
		&:not(:last-of-type) {
			margin-bottom: clamp(5rem, 8vw, 8rem);
		}
		.txt {
			margin-top: 1rem;
		}
	}
	&__map {
		width: 100%;
	}
	&__inner {
		position: relative;
		padding: 0;
		height: 0;
		overflow: hidden;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			padding-bottom: 40%;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 360px;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
			vertical-align: bottom;
		}
	}
}

/* news-cont */
.news-cont {
	@include cont-padding;
	background: $p-gray;
	.common-table {
		th {
			@include f-family(font02);
		}
	}
}

/* blog-cont */
.blog-cont {
	@include cont-padding;
	.l-cont {
		position: relative;
	}
	.btn-wrap {
		@include m-a;
		width: 100%;
		max-width: 360px;
		& button {
			@include f-em(18);
		}
	}
}
.blog-cont__box2-list {
	margin-bottom: clamp(3rem, 4vw, 4rem);
	@include media-breakpoint-up(lg) {
		@include flex-wrap;
		column-gap: 4.16%;
		& > * {
			width: 47.91%;
		}
	}
}
