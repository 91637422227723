//===================================================
//	color
//===================================================
// main colors
$main_c:    #D20F0F;//メインカラー
$sub_c:     #F1EADD;//サブカラー
$point_c:   #3B2909;//ポイントカラー
$txt_c:     #202020;//テキストカラー
$link_c:    $main_c;//リンク基本カラー

// other colors
$other_c1:	#AA0000;//アザーカラー1
$other_c2:	#fdfbf6;//アザーカラー2
$other_c3:	#78CAF2;//アザーカラー3


// backgraound
$main_bg:   #F9F4E8;//メインバックグラウンドカラーー

@mixin bg_dot($dot_color:$m-gray,$bg_color:$white) { //ドット背景
	background-color: $bg_color;
	background-image: radial-gradient($dot_color 8.33%, transparent 8.33%);
	background-size: 24px 24px;
}


// mono tone
$white:     #fff;   //ホワイト
$o-white:   #fdfdfd;//オフホワイト
$p-gray:    #f6f6f6;   //ペールグレー
$l-gray:    #e0e4e6;   //ライトグレー
$m-gray:    #c9ced2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #232729;   //ブラックグレー
$black:     #0c0601;   //ブラック

// color tone
$red:       #D93838;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク

// theme-colors
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "main_c":     $main_c,
    "sub_c":      $sub_c,
    "point_c":    $point_c,
    "txt_c":      $txt_c,
    "link_c":     $link_c,
    "white":      $white,
    "gray":       $gray,
    "black":      $black
  ),
  $theme-colors
);

